import React, { useState, useEffect, createContext } from 'react';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import config from 'config/firebase';

export const firebaseContext = createContext();
const { Provider } = firebaseContext;

export default function FirebaseContext({ children }) {
  const [db, setDb] = useState(null);
  const [auth, setAuth] = useState(null);
  const [admin, setAdmin] = useState(null);

  const initApp = async () => {
    const firebaseApp = firebase.initializeApp(config);
    setDb(firebaseApp.database());
    setAuth(firebaseApp.auth());
  };

  useEffect(() => {
    initApp();
  }, []);

  useEffect(() => {
    if (auth) {
      auth.onAuthStateChanged(user => setAdmin(user || { user: false }));
    }
  }, [auth]);

  return <Provider value={{ db, auth, admin }}>{children}</Provider>;
}
