import React from 'react';
import Container from 'container';
import Headline from 'headline';
import s from './footer.module.scss';
import NavigationHelper from './navigation';
import SocialHelper from './social';
import EmailForm from './form';
import PicturesBanner from './pictures-banner';

export default function Footer({ picturesBanner, navigate, social, ...props }) {
  return (
    <>
      <PicturesBanner {...picturesBanner} />
      <Container className={s.section}>
        <div className={s.wrapper}>
          <div className={s.linksRow}>
            <NavigationHelper {...navigate} />
            <SocialHelper {...social} />
          </div>
          <div className={s.suscriptionRow}>
            <div className={`${s.whiteBackground} ${s.joinTxt}`}>
            <Headline h3 className={s.newsletterSignUp}>
                {' '}
                JOIN OTHER 45,000 MANIFESTORS
                {' '}
              </Headline>
            </div>
            <div className={s.whiteBackground}>
              <Headline h3 className={s.newsletterSignUp}>
                {' '}
                SIGN UP TO OUR
                {' '}
              </Headline>
              <Headline h1 tertiary wild className={s.newsletter}>
                {' '}
                Newsletter
                {' '}
              </Headline>
            </div>

            <EmailForm />
          </div>
        </div>
      </Container>
    </>
  );
}
