import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import { Link } from 'gatsby';
import s from './navigation.module.scss';

export default function NavigationHelper({ links, title }) {
  return (
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
        <Headline className={s.title} terciary h2 center html={title} />
      </div>
      {links.map(({ text, link }, i) => (
        <Link className={s.navigationLink} to={link}>
          {text}
        </Link>
      ))}
    </div>
  );
}
