import React from 'react';
import Icon from 'icon';

import s from './container.module.scss';

export default ({
  children,
  className,
  noSection,
  primary,
  secondary,
  tertiary,
  grey,
  ...props
}) => {
  const primaryClasses = primary ? s.primary : '';
  const secondaryClasses = secondary ? s.secondary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const greyClasses = grey ? s.grey : '';
  const colorClasses = primaryClasses || secondaryClasses || tertiaryClasses || greyClasses;
  const classes = `${s.container} ${className
    || ''} ${colorClasses}`;
  return noSection ? (
    <div className={`container ${className || ''}`}>{children}</div>
  ) : (
    <section className={classes} {...props}>
      <div className="container">{children}</div>
    </section>
  );
};
