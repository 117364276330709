import React from 'react';


export default ({ className, ...other }) => (

  <svg id="facebook-logo-button" xmlns="http://www.w3.org/2000/svg" width="46.644" height="46.319" viewBox="0 0 46.644 46.319">
    <g id="Group_45" data-name="Group 45">
      <path id="Path_110" data-name="Path 110" d="M23.322,0A23.16,23.16,0,1,0,46.644,23.159,23.268,23.268,0,0,0,23.322,0Zm5.8,23.975H25.327V37.4H19.705V23.975H17.032V19.228h2.673v-3.07c0-2.2,1.052-5.635,5.674-5.635l4.166.016v4.607H26.521a1.141,1.141,0,0,0-1.192,1.293v2.789h4.284Z" fill="#455972" />
    </g>
  </svg>


);
