import React from 'react';
import Container from 'container';
import s from './footerlp.module.scss';

export default function FooterLP({ contact, terms, privacy, ...props }) {
  return (
    <Container className={s.root}>
      <ul className={s.wrapper}>
        {/* <li className={s.item}>
          <a className={`${s.item} ${s.link}`} href={contact.href}>
            {contact.text}
          </a>
        </li> */}
        {/* <div className={s.line} /> */}
        <li className={s.item}>
          <a className={`${s.item} ${s.link}`} href={terms.href}>
            {terms.text}
          </a>
        </li>
        <div className={s.line} />
        <li className={s.item}>
          <a className={`${s.item} ${s.link}`} href={privacy.href}>
            {privacy.text}
          </a>
        </li>
      </ul>
    </Container>
  );
}
