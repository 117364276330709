import React, { useState } from 'react';

export const context = React.createContext();
const { Provider } = context;

export default function TemplatesProvider({ children, ...props }) {
  const [templates, setTemplates] = useState(props.templates);
  const [keysMode, setKeysMode] = useState(false);
  return (
    <Provider value={{ templates, setTemplates, keysMode, setKeysMode }}>
      {children}
    </Provider>
  );
}
