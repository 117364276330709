import React from 'react';

export default ({ className, ...other }) => (

<svg id="email" xmlns="http://www.w3.org/2000/svg" width="46.319" height="46.319" viewBox="0 0 46.319 46.319">
  <g id="Group_46" data-name="Group 46" transform="translate(0)">
    <path id="Path_112" data-name="Path 112" d="M23.16,0A23.16,23.16,0,1,0,46.32,23.159,23.16,23.16,0,0,0,23.16,0Zm0,8.135,13.7,8.546H9.456ZM37.036,30.328h0a2.481,2.481,0,0,1-2.482,2.482H11.768a2.481,2.481,0,0,1-2.482-2.482v-13.1a2.468,2.468,0,0,1,.039-.424l13.094,8.165c.016.01.033.017.05.026l.052.028a1.374,1.374,0,0,0,.285.111l.03.006a1.413,1.413,0,0,0,.323.041h0a1.387,1.387,0,0,0,.323-.041l.03-.006a1.388,1.388,0,0,0,.285-.111l.052-.028c.016-.009.034-.016.05-.026L37,16.8a2.465,2.465,0,0,1,.039.424v13.1Z" transform="translate(-0.001)" fill="#455972"/>
  </g>
</svg>


);