import React from 'react';
import Layout from '@/layout';
import TemplatesProvider from 'context/templates-context';
import FirebaseProvider from 'context/firebase-context';
import 'styles/project.scss';

export default {
  wrapRootElement: ({ element }) => (
    <FirebaseProvider>{element}</FirebaseProvider>
  ),
  wrapPageElement: ({ element, props }) => {
    const { acfKeys, templates, slug: pageSlug } = props.pageContext;
    const slug = pageSlug || props.pageContext.slug;
    const isTemplates = slug === '/templates';
    const layoutProps = {
      ...(acfKeys ? { ...props, acfKeys, templates } : { ...props })
    };
    const templateNames =
      isTemplates && templates.map(({ acf_fc_layout }) => acf_fc_layout);
    return templateNames ? (
      <TemplatesProvider templates={templateNames}>
        <Layout {...layoutProps}>{element}</Layout>
      </TemplatesProvider>
    ) : (
      <Layout {...layoutProps}>{element}</Layout>
    );
  }
};
