import React from 'react';


export default () => (
  <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="46.319" height="46.319" viewBox="0 0 46.319 46.319">
    <g id="Group_48" data-name="Group 48">
      <g id="Group_47" data-name="Group 47">
        <path id="Path_113" data-name="Path 113" d="M24.459,29.064a4.6,4.6,0,1,0-4.6-4.605A4.611,4.611,0,0,0,24.459,29.064Z" transform="translate(-1.301 -1.301)" fill="#455972" />
        <path id="Path_114" data-name="Path 114" d="M35.326,18.394V13.96l-.577,0-3.857.012.015,4.435Z" transform="translate(-2.052 -0.927)" fill="#455972" />
        <path id="Path_115" data-name="Path 115" d="M23.159,0A23.159,23.159,0,1,0,46.319,23.159,23.186,23.186,0,0,0,23.159,0ZM36.331,20.457V31.241a5.1,5.1,0,0,1-5.091,5.092H15.079a5.1,5.1,0,0,1-5.091-5.092V15.08a5.1,5.1,0,0,1,5.091-5.091h16.16a5.1,5.1,0,0,1,5.092,5.091Z" fill="#455972" />
        <path id="Path_116" data-name="Path 116" d="M31.143,24.613a7.154,7.154,0,1,1-13.782-2.684h-3.9V32.638a2.506,2.506,0,0,0,2.507,2.5H32.011a2.508,2.508,0,0,0,2.507-2.5V21.929H30.612A7.056,7.056,0,0,1,31.143,24.613Z" transform="translate(-0.83 -1.415)" fill="#455972" />
      </g>
    </g>
  </svg>

);
