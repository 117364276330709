import React from 'react';
import { Link } from 'gatsby';
import Icon from 'icon';
import s from './button.module.scss';

export default ({
  href,
  disabled,
  children,
  className,
  primary,
  secondary,
  tertiary,
  type,
  noArrow,
  outlined,
  onClick,
  ...props
}) => {
  const primaryClasses = primary ? s.primary : '';
  const secondaryClasses = secondary ? s.secondary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const classes = `${s.default} ${outlined ? s.outlined : ''} ${className ||
    ''} ${primaryClasses || secondaryClasses || tertiaryClasses}`;

  if (href && href.includes('http') && !onClick) {
    return (
      <a
        href={href}
        className={classes}
        target={!href.includes('#') ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        {children}
        {!noArrow ? <Icon icon="readmorearrow" /> : null}
      </a>
    );
  }
  if (href && !onClick) {
    return (
      <Link {...props} className={classes} to={href} className={classes}>
        {children}
        {!noArrow ? <Icon icon="readmorearrow" /> : null}
      </Link>
    );
  }
  return (
    <button
      type={type || 'button'}
      {...{
        onClick,
        disabled
      }}
      className={classes}
    >
      {children}
      {!noArrow ? <Icon icon="readmorearrow" /> : null}
    </button>
  );
};
