import React from 'react';
import Footer from './footer_lp';

const footerData = {
  contact: {
    text: 'CONTACT',
    href: '/breakthrough-long-lp/'
  },
  terms: {
    text: 'TERMS & CONDITIONS',
    href: '/tos/'
  },
  privacy: {
    text: 'PRIVACY POLICY',
    href: '/privacy-policy/'
  }
};
export default function LpLayout({ children, ...props }) {
  return (
    <>
      {children}
      <Footer {...footerData} />
    </>
  );
}
