const validate = (values) => {
  const errors = {};

  if (values.email?.touched) {
    if (!values.email.value) {
      errors.email = 'Your email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email.value)) {
      errors.email = 'Invalid email address';
    }
  }

  if (values.name?.touched) {
    if (!values.name.value) {
      errors.name = 'Your name is required';
    } else if (!/([a-zA-Z]+?\s[a-zA-Z]+?).+/i.test(values.name.value)) {
      errors.name = 'First and last Name are required';
    }
  }

  if (values.password?.touched) {
    if (!values.password.value) {
      errors.name = 'Password is required';
    }
  }

  return errors;
};

export default validate;
