import React, { useState } from 'react';
import Container from 'container';
import Icon from 'icon';
import Logo from 'components/shared/logo';
import { Link } from 'gatsby';
import s from './header.module.scss';

export default function HeaderSection({ links, text, ...props }) {
  const [openNav, setOpenNav] = useState(false);

  return (
    <nav>
      <Container className={s.section}>
        <div className={s.wrapper}>
          <Logo />
          <div className={s.burger} onClick={() => setOpenNav(true)} role="button" tabIndex="0">
            <Icon icon="menu" />
          </div>

          <ul className={`${s.linksContainer} ${openNav ? s.openNav : ''}`}>
            <div
              className={s.xIcon}
              role="button"
              tabIndex="0"
              onClick={() => setOpenNav(false)}
            >
              <Icon className={s.close} icon="close" />
            </div>
            {links.map(({ text, href, hide_desktop }) => (
              <li className={`${s.link} ${hide_desktop && s.hideDesktop}`} onClick={() => setOpenNav(false)}>
                <Link
                  key={text}
                  to={href}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </nav>
  );
}
