import React from 'react';
import Img from 'gatsby-image';
import imageMap from '@/images/maps/src-map.json';

export default function Image(props) {
  const { src, name, image, ...imageProps } = props;
  try {
    const imageSplit = src.split(' ');
    const joinImages =
      imageSplit.length > 1 &&
      imageSplit
        .join('-')
        .toLowerCase()
        .replace('.', '')
        .replace(',', '')
        .replace('(', '')
        .replace(')', '');
    const toLowerCaseImage = joinImages || String(src).toLowerCase();
    const fluid = { ...imageMap[toLowerCaseImage], sizes: '(max-width: 800px) 100vw, 3000px' };
    if (!fluid) {
      throw String(`${src}, ${name}`);
    }
    return <Img fluid={fluid} {...imageProps} />;
  } catch (source) {
    return <div />;
  }
}
