import React from 'react';
import Headline from 'headline';
import Img from 'img';
import s from './image.module.scss';

export default function imagesComponent({ headline, images }) {
  return (
    <div className={s.wrapper}>
      <div className={s.whiteBackground}>
        {headline && (
        <>
          <Headline className={`${s.ad}`} h1 wild html={headline.charAt(0)} />
          <Headline className={s.instagram} h3 html={headline.substring(1)} />
        </>
        )}
      </div>
      <div className={s.imageRow}>
        {images.map(({ image }) => (
          <div className={s.imageWrapper}>
            <Img src={image} alt={image} />
          </div>
        ))}
      </div>
    </div>
  );
}
