import React from 'react';


export default ({ className, ...other }) => (

<svg xmlns="http://www.w3.org/2000/svg" width="47.319" height="47.321" viewBox="0 0 47.319 47.321">
  <g id="pinterest-logo" transform="translate(0.5 0.5)">
    <path id="Path_111" data-name="Path 111" d="M43.224,11.535A23.063,23.063,0,0,0,34.8,3.106,22.654,22.654,0,0,0,23.169,0,22.654,22.654,0,0,0,11.544,3.106a23.054,23.054,0,0,0-8.429,8.429A22.651,22.651,0,0,0,.008,23.161a22.451,22.451,0,0,0,3.83,12.756A23.082,23.082,0,0,0,13.88,44.392a19.979,19.979,0,0,1,.392-5.338l2.986-12.606a8.043,8.043,0,0,1-.754-3.679,6.908,6.908,0,0,1,1.3-4.282,3.855,3.855,0,0,1,3.167-1.719,2.83,2.83,0,0,1,2.322,1,3.822,3.822,0,0,1,.814,2.5,9.365,9.365,0,0,1-.347,2.277q-.347,1.342-.9,3.106t-.8,2.819a3.424,3.424,0,0,0,.694,3.152A3.689,3.689,0,0,0,25.7,32.932q3.227,0,5.293-3.588a17.208,17.208,0,0,0,2.065-8.716A8.55,8.55,0,0,0,30.511,14.2a9.772,9.772,0,0,0-7.1-2.473,10.98,10.98,0,0,0-11.4,11.1,6.859,6.859,0,0,0,1.538,4.554,1.376,1.376,0,0,1,.332,1.3q-.06.181-.241.9c-.12.482-.2.795-.241.935q-.241.965-1.146.6a6.8,6.8,0,0,1-3.528-3.347,12.059,12.059,0,0,1-1.206-5.519,13.156,13.156,0,0,1,.649-4.041,13.783,13.783,0,0,1,2.02-3.906,15.868,15.868,0,0,1,3.287-3.332,15.424,15.424,0,0,1,4.659-2.322,19.362,19.362,0,0,1,5.911-.875,15.517,15.517,0,0,1,7.69,1.9,13.574,13.574,0,0,1,5.2,4.916,12.41,12.41,0,0,1,1.794,6.454,20.29,20.29,0,0,1-1.568,8.142,13.369,13.369,0,0,1-4.434,5.684,10.857,10.857,0,0,1-6.515,2.066,7.155,7.155,0,0,1-3.438-.859,5.028,5.028,0,0,1-2.2-2.036q-1.357,5.337-1.629,6.363a18.55,18.55,0,0,1-2.352,4.945,23.159,23.159,0,0,0,18.2-2.142,23.05,23.05,0,0,0,8.429-8.428A22.658,22.658,0,0,0,46.327,23.16,22.648,22.648,0,0,0,43.224,11.535Z" transform="translate(-0.008 0)" fill="#455972" stroke="#707070" stroke-width="1"/>
  </g>
</svg>



);