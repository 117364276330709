import React from 'react';
import Headline from 'headline';
import { Link } from 'gatsby';
import s from './logo.module.scss';

export default function Logo() {
    return (
        <Link to="/">
          <Headline h3 tertiary className={s.title}>LAURIE·ANNE <span className={s.span}>King</span></Headline>
        </Link>
    )
}