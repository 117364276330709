import Root from './root';
import 'styles/project.scss';
import smoothscroll from 'smoothscroll-polyfill';
// polyfills
const isServer = typeof window === 'undefined';
if (!isServer) {
  // trigger polyfill in client only so build doesnt break
  smoothscroll.polyfill();
}

export const { wrapRootElement } = Root;
export const { wrapPageElement } = Root;
