import React from 'react';

export default ({ className, ...other }) => (

  <svg id="Group_94" data-name="Group 94" xmlns="http://www.w3.org/2000/svg" width="46.319" height="46.319" viewBox="0 0 46.319 46.319">
    <g id="youtube">
      <path id="Path_117" data-name="Path 117" d="M224.113,216.775l7.584-4.368-7.584-4.368Zm0,0" transform="translate(-203.865 -189.248)" fill="#455972" />
      <path id="Path_118" data-name="Path 118" d="M23.159,0A23.159,23.159,0,1,0,46.319,23.159,23.162,23.162,0,0,0,23.159,0ZM37.631,23.183a38.224,38.224,0,0,1-.6,6.962A3.626,3.626,0,0,1,34.484,32.7c-2.265.6-11.324.6-11.324.6s-9.036,0-11.324-.62a3.627,3.627,0,0,1-2.551-2.551,38.056,38.056,0,0,1-.6-6.961,38.2,38.2,0,0,1,.6-6.961,3.7,3.7,0,0,1,2.551-2.575c2.265-.6,11.324-.6,11.324-.6s9.059,0,11.324.62A3.627,3.627,0,0,1,37.035,16.2,36.273,36.273,0,0,1,37.631,23.183Zm0,0" fill="#455972" />
    </g>
  </svg>

);
